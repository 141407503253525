import React from "react";
import services from "../../data/services.json";
import { Layout, ServicePage } from "../../components";

export default ({ location }) => {
  const data = services.data[location.pathname.split('/').filter(Boolean).slice(-1)[0]];
  return (
    <Layout pageContext={data}>
      <ServicePage pageContext={data} />
    </Layout>
  )
}
